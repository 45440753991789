import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import markosjolund from '../images/markosjolund.jpg';
import miljavepsalainen from '../images/miljavepsalainen.jpg';
import Typography from '@mui/material/Typography';

function ContactCard({ name, expertise, picture, email, phone }) {
    return (
        <Card style={{ width: 200, margin: '10px' }}>
            <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body2" color="textSecondary">{expertise}</Typography>
                <CardMedia
                    component="img"
                    style={{ width: '100%', marginTop: '10px' }}
                    image={picture}
                    alt={name}
                />
                <Link href={`mailto:${email}`} variant="body2" color="textSecondary" style={{ marginTop: '10px', display: 'block' }}>
                    {email}
                </Link>
                <Typography variant="body2" color="textSecondary">{phone}</Typography>
            </CardContent>
        </Card>
    );
}

function Contact() {
    return(
    <div>
        <Typography style={{ marginTop: '20px', fontSize: '1.5rem', fontWeight: 'bold' }}>
            Yhteystiedot
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <ContactCard
                name="Marko Sjölund"
                expertise="DI ympäristötekniikka, RAP-pätevyys"
                picture={markosjolund}
                email="marko.sjolund@envipro.fi"
                phone="040 509 4660"
            />
            <ContactCard
                name="Milja Vepsäläinen"
                expertise="MMT, ympäristömikrobiologia"
                picture={miljavepsalainen}
                email="milja.vepsalainen@envipro.fi"
                phone="040 823 5892"
            />
        </div>
    </div>
    );
}

export default Contact;