import React from 'react';
import Typography from '@mui/material/Typography';

function Services() {
    return (
        <div>
            <Typography style={{ marginTop: '20px' }}>
                Tarjoamme laajan kirjon ympäristöön liittyviä palveluita.
            </Typography>
            <ul style={{ textAlign: 'left', display: 'inline-block', marginTop: '20px' }}>
                <li>Pilaantuneiden maiden tutkimukset, kunnostusten suunnittelut, valvonta ja riskinarvioinnit</li>
                <li>Urakka-asiakirjojen laadinta ja rakennuttaminen</li>
                <li>Kaatopaikkarakenteiden riippumaton laadunvalvonta</li>
                <li>Ympäristö- ja vesilain mukaiset lupahakemukset</li>
                <li>Maa-ainesten ottosuunnitelmat</li>
                <li>Pohjavesi- ja vesistötarkkailut</li>
                <li>Sedimenttitutkimukset ja niihin liittyvä suunnittelu</li>
                <li>Ympäristötekninen näytteenotto</li>
                <li>Jakeluasemien rakentamisen laadunvalvonta</li>
                <li>Environmental due diligence -selvitykset (EDD)</li>
                <li>Ympäristöön liittyvät erillisselvitykset</li>
                <li>Rakennuttamispalvelut</li>
            </ul>
        </div>
    );
}

export default Services;