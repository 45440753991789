import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import logo from './images/logo.png';
import FrontPage from './pages/FrontPage';
import Services from './pages/Services';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <div className="App" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
        <AppBar position="static" sx={{ backgroundColor: '#1F884D' }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: '200px', margin: '5px 10px' }} />
            </Link>
            <div>
              <Button color="inherit" component={Link} to="/palvelut">
                Palvelut
              </Button>
              <Button color="inherit" component={Link} to="/yhteystiedot">
                Yhteystiedot
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Container
          maxWidth="lg"
          sx={{
            textAlign: 'center',
            marginTop: '20px',
            backgroundColor: 'white',
            padding: '20px',
            minHeight: 'calc(100vh - 64px)', // Adjusting for AppBar height
          }}
        >
          <Box>
            <Routes>
              <Route path="/" element={<FrontPage />} />
              <Route path="/palvelut" element={<Services />} />
              <Route path="/yhteystiedot" element={<Contact />} />
            </Routes>
          </Box>
        </Container>
      </div>
    </Router>
  );
}

export default App;
