import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import frontpage3 from '../images/frontpage3.jpg';

function FrontPage() {
    return (
        <Box
            sx={{
                position: 'relative',
                height: "100vh",
                overflow: 'hidden'
            }}
        >
            <Box
                component="img"
                src={frontpage3}
                alt="frontpage3"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 1 // Ensure the image stays behind the content
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    textAlign: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '20px',
                    borderRadius: '10px',
                    width: { xs: '90%', sm: '80%', md: '70%', lg: '60%' },
                    maxWidth: '600px',
                    margin: '0 auto',
                    zIndex: 2 // Ensure the content stays in front of the image
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' } }}>
                    Ympäristöön liittyvät suunnittelu-, näytteenotto- ja valvontapalvelut yli 20 vuoden kokemuksella.
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.6rem' } }}>
                    Intohimonamme on löytää sinulle sopivin ratkaisu. Yhdessä.
                </Typography>
            </Box>
        </Box>
    );
}

export default FrontPage;
